import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import ReactFlow, {
  Background,
  BaseEdge,
  Controls,
  EdgeLabelRenderer,
  MarkerType,
  NodeResizeControl,
  NodeResizer,
  NodeToolbar,
  Panel,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  getBezierPath,
  useReactFlow,
} from 'reactflow';

import 'reactflow/dist/style.css';

import { Handle, Position } from 'reactflow';
import { Add, Email, LinkedIn } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import getOrgChart from 'redux/actions/Common/getOrgChart';
import createOrUpdateOrgChart from 'redux/actions/Common/createOrUpdateOrgChart';
import getMembersByOrgId from 'redux/actions/Compass/getMembersByOrgId';
import getAllTeamMembers from 'redux/actions/Common/getAllTeamMembers';
import deepEqual from 'deep-equal';
import showAlert from 'redux/actions/Common/showAlert';

const handleStyle = { left: 10 };

function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd = {},
  data,
  editable,
}) {
  const theme = useTheme();
  const { setEdges } = useReactFlow();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);
  const [relationshipName, setRelationshipName] = useState('');

  // console.log(sourcePosition);
  // console.log(targetPosition);
  console.log(editable);

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{
          stroke:
            sourcePosition === 'right' || sourcePosition === 'left'
              ? '#000'
              : theme.palette.primary.main,
        }}
      />
      {sourcePosition === 'right' || sourcePosition === 'left' ? (
        <EdgeLabelRenderer>
          <Box
            sx={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              pointerEvents: 'all',
            }}
            className="nodrag nopan"
          >
            {/* {sourcePosition === 'right' || sourcePosition === 'left' ? ( */}
            {data?.label ? (
              <Chip label={data?.label} color="primary" />
            ) : editable ? (
              <Tooltip title="add relationship name" arrow placement="top">
                <Box
                  sx={{
                    cursor: 'pointer',
                    background: '#d3d3d3',
                    padding: '4px 4px 2px 4px',
                    borderRadius: '4px',
                  }}
                  onClick={(e) => {
                    setAnchorEl(e.target);
                    setOpenedPopoverId(id);
                  }}
                >
                  <Add sx={{ fontSize: '14px' }} />
                </Box>
                <Popover
                  elevation={3}
                  id={id}
                  open={openedPopoverId === id}
                  anchorEl={anchorEl}
                  onClose={() => {
                    setAnchorEl(null);
                    setOpenedPopoverId(null);
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  sx={{
                    '.MuiPaper-root': {
                      background: '#fff',
                      border: '1px solid #d3d3d3',
                      borderRadius: 1,
                      padding: 1,
                    },
                  }}
                >
                  <Box display={'flex'} gap={1} alignItems={'center'}>
                    <TextField
                      autoFocus
                      label="Relationship Name"
                      placeholder="Enter Relationship Name, e.g. Price Negotiation"
                      variant="outlined"
                      color="primary"
                      size="small"
                      name="relationshipName"
                      value={relationshipName}
                      onChange={(e) => {
                        setRelationshipName(e.target.value);
                      }}
                    />
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<Add />}
                      onClick={() => {
                        setEdges((prev) =>
                          prev.map((edge) => {
                            return edge?.id === openedPopoverId
                              ? {
                                  ...edge,
                                  data: {
                                    label: relationshipName,
                                  },
                                }
                              : edge;
                          }),
                        );
                        setRelationshipName('');
                      }}
                    >
                      Add
                    </Button>
                  </Box>
                </Popover>
              </Tooltip>
            ) : null}
            {/* ) : null} */}
          </Box>
        </EdgeLabelRenderer>
      ) : null}
    </>
  );
}

function MemberNode({ data }) {
  const theme = useTheme();
  const onChange = useCallback((evt) => {
    // console.log(evt.target.value);
  }, []);

  // console.log(data);

  return (
    <>
      <Handle type="target" position={Position.Top} id="top" />
      <Card
        sx={{
          width: 200,
          border:
            data?.type === 'prospect'
              ? `2px solid ${theme.palette.primary.main}`
              : '2px solid brown',
        }}
      >
        <Box p={2}>
          <Typography variant="body1" color={'text.secondary'}>
            {data?.name}
          </Typography>
          <Typography variant="caption" color={'text.secondary'}>
            {data?.designation}
          </Typography>
          {/* <Typography variant="caption" color={'text.secondary'}>
            {data?.type}
          </Typography> */}
        </Box>
        <Divider />
        <Box display={'flex'} gap={1} p={1}>
          <LinkedIn sx={{ fontSize: 18 }} />
          <Email sx={{ fontSize: 18 }} />
        </Box>
      </Card>
      <Handle type="source" position={Position.Bottom} id="bottom" />
      <Handle type="source" position={Position.Left} id="left" />
      <Handle type="source" position={Position.Right} id="right" />
    </>
  );
}

// const initialNodes = prospects?.map(prospect => {
//   return {
//     id: prospect?.id,
//     type: 'textUpdater',
//     position: { x: 0, y: 0 },
//     data: { member: {} },
//   }
// })
// [
//   {
//     id: 'node-1',
//     type: 'textUpdater',
//     position: { x: 0, y: 0 },
//     data: { member: {} },
//   },
//   // { id: '1', position: { x: 300, y: 0 }, data: { label: '1' }, type: 'input' },
//   // { id: '8', position: { x: 600, y: 0 }, data: { label: '8' }, type: 'input' },
//   // { id: '9', position: { x: 900, y: 0 }, data: { label: '9' }, type: 'input' },
//   // { id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
//   // { id: '3', position: { x: 600, y: 100 }, data: { label: '3' } },
//   // { id: '4', position: { x: 0, y: 200 }, data: { label: '4' } },
//   // { id: '5', position: { x: 200, y: 200 }, data: { label: '5' } },
//   // { id: '6', position: { x: 400, y: 200 }, data: { label: '6' } },
//   // { id: '7', position: { x: 600, y: 200 }, data: { label: '7' } },
// ];

const initialEdges = [
  // { id: 'e1-2', source: '1', target: '2' },
  // { id: 'e1-3', source: '1', target: '3' },
  // { id: 'e2-4', source: '2', target: '4' },
  // { id: 'e2-5', source: '2', target: '5' },
  // { id: 'e2-6', source: '2', target: '6' },
  // { id: 'e3-7', source: '3', target: '7' },
];

const OrgChart = ({
  prospects,
  currentAccount,
  teamMembers,
  loading,
  editMode,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [fetchingOrgChart, setFetchingOrgChart] = useState(false);

  const nodeTypes = useMemo(() => ({ memberNode: MemberNode }), []);
  const edgeTypes = useMemo(
    () => ({
      customEdge: (props) => (
        <CustomEdge editable={editMode ? true : false} {...props} />
      ),
    }),
    [],
  );

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState(initialEdges);

  const reactFlowRef = useRef(null);
  // const [nodesOrEdgeUpdated, setNodesOrEdgeUpdated] = useState(false);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) => {
        console.log(params);
        // setNodesOrEdgeUpdated(true);
        return addEdge(
          {
            ...params,
            type: 'customEdge',
            data: {
              label: '',
            },
            markerEnd:
              (params?.sourceHandle === 'right' &&
                params?.targetHandle === 'left') ||
              (params?.sourceHandle === 'left' &&
                params?.targetHandle === 'right')
                ? {
                    type: MarkerType.ArrowClosed,
                    width: 30,
                    height: 30,
                    color: '#000',
                  }
                : {},
            animated:
              (params?.sourceHandle === 'right' &&
                params?.targetHandle === 'left') ||
              (params?.sourceHandle === 'left' &&
                params?.targetHandle === 'right')
                ? false
                : false,
          },
          eds,
        );
      }),
    [setEdges],
  );

  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => {
        // setNodesOrEdgeUpdated(true);
        return applyNodeChanges(changes, nds);
      });
    },
    [setNodes],
  );

  const onEdgesChange = useCallback(
    (changes) =>
      setEdges((eds) => {
        // setNodesOrEdgeUpdated(true);
        return applyEdgeChanges(changes, eds);
      }),
    [setEdges],
  );

  const handleSave = () => {
    dispatch(
      createOrUpdateOrgChart(
        currentAccount?.id,
        {
          nodes: nodes?.map((node) => {
            return {
              id: node?.id,
              type: 'memberNode',
              position: { x: node?.position?.x, y: node?.position?.y },
              width: 200,
              height: 115,
            };
          }),
          edges: edges,
        },
        (error) => {
          if (error) {
            dispatch(
              showAlert({
                message:
                  'Failed to update organization chart. Please try again later!',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          } else {
            dispatch(
              showAlert({
                message: 'Organization chart updated successfully.',
                showCross: true,
                title: null,
                type: 'success',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          }
        },
      ),
    );
  };

  useEffect(() => {
    if (!loading) {
      setFetchingOrgChart(true);
      dispatch(
        getOrgChart(currentAccount?.id, (orgChart) => {
          if (orgChart?.nodes && orgChart?.nodes?.length > 0) {
            setNodes(orgChart?.nodes);
            setTimeout(() => {
              setFetchingOrgChart(false);
            }, 500);
          } else {
            setNodes((prev) => [
              ...(prev || []),
              ...(prospects?.map((prospect, index) => {
                return {
                  id: prospect?.id,
                  type: 'memberNode',
                  position: { x: 200, y: index * 150 },
                  data: { ...prospect },
                  width: 200,
                  height: 115,
                };
              }) || []),
            ]);
            dispatch(
              getMembersByOrgId(25, null, (members) => {
                dispatch(
                  getAllTeamMembers(null, null, 25, null, (teamMembers) => {
                    setNodes((prev) => {
                      return [
                        ...(prev || []),
                        ...(members
                          ?.filter(
                            (m) =>
                              m?.type === 'existing_team_member' ||
                              m?.type === 'non_existing_team_member',
                          )
                          ?.map((m, mIndex) => {
                            return {
                              id: m?.id,
                              type: 'memberNode',
                              position: { x: 500, y: mIndex * 150 },
                              data: { ...m },
                              width: 200,
                              height: 115,
                            };
                          }) || []),
                        ...teamMembers
                          ?.filter(
                            (m) =>
                              !members ||
                              !members?.map((mm) => mm?.id)?.includes(m?.id),
                          )
                          ?.map((m, tmIndex) => {
                            return {
                              id: m?.id,
                              type: 'memberNode',
                              position: {
                                x: 1000,
                                y: (members?.length + tmIndex) * 150,
                              },
                              data: {
                                name: m?.fullName,
                                id: m?.id,
                                email: m?.email,
                                designation: '',
                                linkedinUrl: '',
                                type: 'existing_team_member',
                              },
                              width: 200,
                              height: 115,
                            };
                          }),
                      ];
                    });
                    setTimeout(() => {
                      setFetchingOrgChart(false);
                    }, 500);
                  }),
                );
              }),
            );
          }
          setEdges(
            orgChart?.edges?.map((edge) => {
              return {
                ...edge,
              };
            }) || [],
          );
        }),
      );
    }
  }, [prospects, loading]);

  useEffect(() => {
    if (!editMode && reactFlowRef.current) {
      console.log(reactFlowRef.current);
    }
  }, [editMode, reactFlowRef.current]);

  // console.log(edges);

  return (
    <>
      {loading || fetchingOrgChart ? (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          gap={4}
          width={1}
          height={1}
        >
          <CircularProgress size={20} />
          <Typography>
            Please wait while we load the organization chart ...
          </Typography>
        </Box>
      ) : (
        <>
          <ReactFlow
            panOnDrag
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            nodes={nodes}
            edges={edges}
            onNodesChange={editMode ? onNodesChange : () => {}}
            onEdgesChange={editMode ? onEdgesChange : () => {}}
            onConnect={editMode ? onConnect : () => {}}
            fitView
            defaultEdgeOptions={{ animated: false }}
            connectionMode="loose"
            snapToGrid={true}
            nodesConnectable={editMode ? true : false}
            nodesDraggable={editMode ? true : false}
            edgesUpdatable={editMode ? true : false}
          >
            <Controls showInteractive={editMode ? true : false} />
            <NodeToolbar />
            <NodeResizer />
            <NodeResizeControl />
            <Panel />
            <Background variant="dots" gap={12} size={1} />
          </ReactFlow>
          {editMode ? (
            <Button
              variant="contained"
              size="small"
              // disabled={!nodesOrEdgeUpdated}
              sx={{
                padding: '4px 8px',
                position: 'absolute',
                bottom: 16,
                right: 16,
              }}
              onClick={() => {
                handleSave();
              }}
            >
              Update
            </Button>
          ) : null}
        </>
      )}
    </>
  );
};

OrgChart.propTypes = {
  prospects: PropTypes.array,
  currentAccount: PropTypes.object,
  teamMembers: PropTypes.array,
  loading: PropTypes.bool,
  editMode: PropTypes.bool,
};
MemberNode.propTypes = {
  data: PropTypes.object,
};

CustomEdge.propTypes = {
  id: PropTypes.string,
  sourceX: PropTypes.number,
  sourceY: PropTypes.number,
  targetX: PropTypes.number,
  targetY: PropTypes.number,
  sourcePosition: PropTypes.number,
  targetPosition: PropTypes.number,
  style: PropTypes.object,
  data: PropTypes.object,
  markerEnd: PropTypes.number,
  editable: PropTypes.bool,
};

export default OrgChart;
