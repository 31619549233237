import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';
import showAlert from './showAlert';

const getAllTeamMembers = (teamId, wlOrgId, size, lastSeen, callback) => {
  return async (dispatch) => {
    if (lastSeen) {
      dispatch({ type: 'FETCHING_ALL_TEAM_MEMBERS_WITH_PAGINATION' });
    } else {
      dispatch({ type: 'FETCHING_ALL_TEAM_MEMBERS' });
    }
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(`/us/org/members`, {
            params: {
              'request-id': reqId,
              lastSeen: lastSeen,
              limit: size,
              teamId: teamId,
              wlCustomerId: wlOrgId,
            },
            headers: token
              ? {
                  Authorization: `Bearer ${token}`,
                  // 'current-role': currentRole,
                  // 'x-api-version': 'v2',
                }
              : {},
          });
          if (response.status === 200) {
            if (lastSeen) {
              await dispatch({
                type: 'ADD_TEAM_MEMBERS_TO_ALL_TEAM_MEMBERS',
                payload: {
                  members: response?.data,
                  lastSeen:
                    response?.data?.[response?.data?.length - 1]?.createdOn,
                },
              });
            } else {
              await dispatch({
                type: 'FETCHING_ALL_TEAM_MEMBERS_DONE',
                payload: {
                  members: response?.data,
                  lastSeen:
                    response?.data?.[response?.data?.length - 1]?.createdOn,
                },
              });
            }
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'FETCHING_ALL_TEAM_MEMBERS_FAILED',
              payload: 'Failed to fetch team members',
            });
            dispatch(
              showAlert({
                message: 'Oops! Something went wrong. Please try again.',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          }
        } catch (e) {
          dispatch({
            type: 'FETCHING_ALL_TEAM_MEMBERS_FAILED',
            payload: 'Failed to fetch team members',
          });
          dispatch(
            showAlert({
              message: 'Oops! Something went wrong. Please try again.',
              showCross: true,
              title: null,
              type: 'error',
              autoHideDuration: 2000,
              vertical: 'top',
              horizontal: 'center',
            }),
          );
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'FETCHING_ALL_TEAM_MEMBERS_FAILED',
        payload: 'Failed to fetch team members',
      });
      dispatch(
        showAlert({
          message: 'Oops! Something went wrong. Please try again.',
          showCross: true,
          title: null,
          type: 'error',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };
};

export default getAllTeamMembers;
